<script lang="ts" setup>
import AdvantageCard from '~/components/cards/AdvantageCard.vue'
import constants from '~/constants'
import Page from '~/enums/page'

const advantages = [
  {
    description: 'Отбираем лучшие аналоги любимых добавок, которые ищете\xA0вы или рекомендует ваш специалист.',
    icon: 'outlined/filter-vintage',
    path: Page.Products,
    title: '99% самых нужных БАД'
  },
  {
    description: 'Тщательно проверяем СГР и\xA0декларации.',
    icon: 'outlined/verified',
    path: Page.Products,
    title: '100% продуктов сертифицированы'
  },
  {
    description: 'Доставляем по\xA0всей России и\xA0СНГ проверенными компаниями.',
    icon: 'outlined/timer',
    path: Page.Delivery,
    title: 'Быстрая доставка'
  },
  {
    description: 'Подберите индивидуальный набор качественных БАД.',
    icon: 'outlined/quiz',
    path: Page.Tests,
    title: 'Тесты'
  },
  {
    description: 'Опытный health-коуч поможет подобрать: аналог iHerb; товары по\xA0списку врача; индивидуальную схему при определённом состоянии.',
    icon: 'outlined/face',
    path: constants.categories.consultations.path,
    title: 'Консультации эксперта'
  }
]
</script>

<template>
  <section>
    <div class="container">
      <h2 class="h1 mb16">
        iTAB
      </h2>

      <p class="mb16">
        Только качественные товары для здоровья, одобренные ведущими врачами России
      </p>

      <div class="row advantages">
        <AdvantageCard
          v-for="(x, i) in advantages"
          :key="x.title"
          class="column-4 tb12 mb24"
          :class="{ 'with-margin-bottom': i < advantages.length - 1 }"
          :description="x.description"
          :icon="x.icon"
          :path="x.path"
          :title="x.title"
        />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

section {
  background: $main-green-color;
  box-sizing: border-box;
  padding: 40px 0;

  @media screen and (max-width: 767px) {
    padding: 32px 0;
  }
}

.container > h2,
.container > p {
  color: white;
}

.advantages {
  margin-bottom: -24px;
}
</style>
