<script lang="ts" setup>
import type { SwiperOptions } from 'swiper'

import BrandCard from '~/components/cards/BrandCard.vue'

const galleryOptions: SwiperOptions = {
  breakpoints: {
    1020: { slidesPerGroup: 1, slidesPerView: 6 },
    960: { slidesPerGroup: 1, slidesPerView: 5 },
    768: { slidesPerGroup: 1, slidesPerView: 4 },
    480: { slidesPerGroup: 1, slidesPerView: 3.3, spaceBetween: 16 },
    360: { slidesPerGroup: 1, slidesPerView: 2.3, spaceBetween: 16 },
    0: { slidesPerGroup: 1, slidesPerView: 1, spaceBetween: 16 }
  },
  lazy: true,
  spaceBetween: 24
}

const props = defineProps<{
  brandsData: any[]
  title: string
}>()
</script>

<template>
  <section v-if="brandsData.length">
    <div class="container">
      <h2 class="h1">
        {{ title }}
      </h2>
    </div>

    <Gallery :options="galleryOptions">
      <BrandCard v-for="x in brandsData" :key="x.id" :brand-data="x" class="swiper-slide max-6-slides" />
    </Gallery>

    <div class="container">
      <div class="row mt24">
        <div class="column-6 tb12">
          <p>Мы&nbsp;предоставляем продукцию, проверенную временем и&nbsp;нашими покупателями, тщательно подходим к&nbsp;подбору партнёров и&nbsp;гарантируем высочайшее качество наших товаров.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import 'assets/css/gallery';
</style>
