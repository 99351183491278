<script lang="ts" setup>
import type { FetchError } from 'ofetch'

import SubscriptionInput from '~/components/form-elements/SubscriptionInput.vue'
import SubscriptionForm from '~/enums/subscription-form'
import ApiErrorHandler from '~/helpers/api-error-handler'
import RetailRocketApiService from '~/services/retail-rocket-api-service'

const props = defineProps<{
  title: string
}>()

const emptyForm = { email: null }
const messages = {
  emailFieldCannotBeEmpty: 'Email не введён.',
  failedToSubscribe: 'Не удалось подписаться.',
  thanksForSubscribing: 'Спасибо за подписку!'
}
const picture = { src: '/images/sections/subscription.jpg' }

const { $apiHelper, $toast } = useNuxtApp()

const form = ref<any>({ ...emptyForm })
const isSubscriberCreating = ref(false)

async function subscribe (): Promise<void> {
  const { email } = form.value
  const { emailFieldCannotBeEmpty, failedToSubscribe, thanksForSubscribing } = messages

  if (!email) {
    $toast.error(emailFieldCannotBeEmpty)

    return
  }

  isSubscriberCreating.value = true

  try {
    await $apiHelper.subscribers.createSubscriber(email, SubscriptionForm.MainPage)

    form.value = { ...emptyForm }

    $toast.success(thanksForSubscribing)

    RetailRocketApiService.setEmail(email)

    RetailRocketApiService.welcomeSequence(email)
  } catch (error) {
    ApiErrorHandler.handleFetchError(error as FetchError, failedToSubscribe)
  }

  isSubscriberCreating.value = false
}
</script>

<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="column-6 tb12 left">
          <div class="h2">
            {{ title }}
          </div>

          <p>Подпишитесь на&nbsp;рассылку с&nbsp;новостями и&nbsp;скидками.</p>

          <SubscriptionInput
            v-model="form.email"
            class="mb8"
            :is-subscribing="isSubscriberCreating"
            placeholder="Введите ваш email"
            @on-button-click="subscribe"
          />

          <Agreement class="agreement" icon-name="outlined/east" />
        </div>
        <div class="column-6 tb12 right">
          <div class="picture">
            <img v-lazy="picture.src" alt="Рассылка" loading="lazy">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
$background-color: #8080ff;

section {
  background: $background-color;
  box-sizing: border-box;
  color: white;
}

.agreement > :deep(a) {
  color: white;
  text-decoration: underline;
}

.left {
  padding: 40px 0;

  @media screen and (max-width: 767px) {
    padding: 32px 0;
  }
}

.right {
  @media screen and (max-width: 600px) {
    display: none;
  }

  .picture {
    background-color: $background-color;
    height: 100%;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
</style>
